import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    // component: Index,
    component: () => import("@/views/index/Index.vue"),
  },
  {
    path: "/news",
    alias: "/zx",
    name: "News",
    component: () => import("@/views/news/News.vue"),
  },
  {
    path: "/details",
    name: "Details",
    component: () => import("@/views/news/details/Index.vue"),
  },
  //产品
  {
    path: "/tea", //茶
    name: "Tea",
    component: () => import("@/views/product/tea/Tea.vue"),
  },
  {
    path: "/health", //保健
    name: "Health",
    component: () => import("@/views/product/health/Health.vue"),
  },
  {
    path: "/society", //政府
    name: "Society",
    component: () => import("@/views/product/society/Society.vue"),
  },
  {
    path: "/industry", //工业
    name: "Industry",
    component: () => import("@/views/product/industry/Industry.vue"),
  },
  {
    path: "/hygiene", //医疗
    name: "Hygiene",
    component: () => import("@/views/product/hygiene/Hygiene.vue"),
  },
  {
    path: "/teaCase",
    alias: "/teaCase",
    name: "Case",
    component: () => import("@/views/case/Case.vue"),
    children: [
      {
        path: "/teaCase", //茶
        component: () => import("@/views/case/component/TeaCase.vue"),
      },
      {
        path: "/teaCaseWeb", //茶
        component: () => import("@/views/case/component/TeaCaseWeb.vue"),
      },
      {
        path: "/healthCase", //保健
        component: () => import("@/views/case/component/HealthCase.vue"),
      },
      {
        path: "/factoryCase", //保健
        component: () => import("@/views/case/component/FactoryCase.vue"),
      },
      {
        path: "/hygieneCase", //医疗
        component: () => import("@/views/case/component/HygieneCase.vue"),
      },
    ],
  },

  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/Home.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
//百度统计
router.beforeEach(async (to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  next();
});

export default router;
