import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import "core-js/stable";
import "regenerator-runtime/runtime";

// import "animate.css";

//百度地图配置
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, { ak: "rbE05lQPbkiqA5wG1YBuuNzGHBHSyvbv" }); ///这里放入上面生成的ak   //百度ak 地址 https://lbsyun.baidu.com/apiconsole/key#/home
import "@/assets/icon/iconfont.css";

//配置ElementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

//导入全局组件
import Footer from "@/components/Footer";
import Navigator from "@/components/Navigator";
Vue.use((Vue) => {
  Vue.component("Footer", Footer);
  Vue.component("Navigator", Navigator);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
