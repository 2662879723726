<template>
  <div class="footer">
    <!-- 回到顶部 -->
    <a href="#returnTop" class="returnPC iconfont icon-xiangshang3"></a>
    <!-- <a href="#webTop" class="returnWeb iconfont icon-xiangshang3"></a> -->
    <!-- 回到顶部 -->

    <img src="../assets/img/logo2.png" alt="" class="img1" />
    <img src="../assets/img/logo5.png" alt="" class="logoWeb" />
    <img src="../assets/img/erweima.png" alt="" class="img2" />
    <i>中国领先的茶产业SaaS系统服务商，运用云计算、RFID、二维码、区块链、智能硬件等技术，为茶产业提供SaaS解决方案。</i>
    <div class="text">
      <p class="p1">公司地址</p>
      <p>江苏省南京市建邺区奥体中心体育馆一楼G105,G106</p>
      <p class="p3">联系电话</p>
      <p>025-83205080</p>
    </div>
      <div style="display: flex;flex-direction: column;" class="keep">
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #fff;margin-bottom: 4px;">苏ICP备18049152号</a>
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32010502010691" target="_blank" style="color: #fff;">苏公网安备32010502010691</a>
      </div>
    <b>关注我们</b>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: true,
    };
  },
  methods: {
    toTop() {
      console.log(document.body.scrollTop);
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 750px) {
  .footer {
    width: 19.17rem;
    background-color: #26488e;
    padding-bottom: 1.38rem;
    display: flex;
    position: relative;
    overflow: hidden;
    .returnPC {
      display: none;
    }
    .keep {
      color: #fff;
      position: absolute;
      right: 5%;
      bottom: 10%;
    }
    i {
      display: block;
      width: 5.27rem;
      font-size: 0.41rem;
      // line-height: 0.51rem;
      // font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
      // font-weight: bold;
      color: #ffffff;
      position: absolute;
      top: 2.23rem;
      left: 1.14rem;
    }
    b {
      font-size: 0.31rem;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #ffffff;
      // line-height: 0.35rem;
      position: absolute;
      right: 1.5rem;
      top: 3.56rem;
    }
    .img1 {
      display: none;
    }
    .logoWeb {
      width: 3.84rem;
      height: 0.6rem;
      position: absolute;
      top: 1rem;
      left: 1.14rem;
    }
    .img2 {
      width: 2.54rem;
      height: 2.54rem;
      position: absolute;
      top: 1rem;
      right: 1.37rem;
    }
    .text {
      width: 7.02rem;
      margin-left: 7.31rem;
      p {
        font-size: 0.41rem;
        color: #fff;
        // font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
        // font-weight: bold;
      }
      .p1 {
        margin-top: 1.2rem;
      }
      .p3 {
        margin-top: 0.68rem;
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .footer {
    width: 19.17rem;
    background-color: #26488e;
    padding-bottom: 1.38rem;
    display: flex;
    position: relative;
    .returnWeb {
      display: none;
    }
    .keep {
      text-align: center;
      color: #fff;
      position: absolute;
      bottom: 10%;
      left: 38%;
    }
    .returnPC {
      width: 0.5rem;
      height: 0.5rem;
      position: fixed;
      right: 0.5rem;
      bottom: 0.5rem;
      font-size: 0.5rem;
      color: #fff;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 50%;
    }
    .returnPC:hover {
      background-color: #2e82ea;
    }
    i {
      display: block;
      width: 5.27rem;
      font-size: 0.24rem;
      font-weight: 600;
      color: #ffffff;
      position: absolute;
      top: 2.23rem;
      left: 1.14rem;
    }
    b {
      font-size: 0.3rem;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #ffffff;
      position: absolute;
      right: 1.96rem;
      top: 3.56rem;
    }
    .logoWeb {
      display: none;
    }
    .img1 {
      width: 3.9rem;
      height: 0.81rem;
      position: absolute;
      top: 1.1rem;
      left: 0.73rem;
    }
    .img2 {
      width: 2.54rem;
      height: 2.54rem;
      position: absolute;
      top: 1rem;
      right: 1.37rem;
    }
    .text {
      width: 7.02rem;
      margin-left: 7.31rem;
      p {
        font-size: 0.24rem;
        color: #fff;
        font-weight: 600;
      }
      .p1 {
        margin-top: 1.2rem;
      }
      .p3 {
        margin-top: 0.68rem;
      }
    }
  }
}
</style>
