import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    color: "rgba(255, 255, 255, 0.8)",
    details: "", //新闻详情内容索引
  },
  mutations: {},
  actions: {},
  modules: {},
});
