<template>
  <div class="header">
    <!-- web -->
    <div class="web" id="webTop">
      <img src="@/assets/img/logo4.png" alt="" v-if="show" class="logo" />
      <div class="icon">
        <Menu />
      </div>
    </div>
    <!-- web -->
    <div class="navigator" ref="bcColor" id="returnTop" @mouseover="mouseoverBjFn" @mouseout="mouseoutBjFn">
      <a href="/" @click="jumpFn">
        <img src="@/assets/img/logo5.png" alt="" v-if="show" class="logo" />
        <img src="@/assets/img/logo4.png" alt="" v-else class="logo" />
      </a>
      <ul class="title">
        <template v-for="(item, i) in title">
          <li :key="i" @click="go(i)" @mouseover="mouseoverLiFn(i)" @mouseout="mouseoutLiFn(i)">
            <a :id="'a' + i" :class="current == i ? 'active' : ''">
              {{ item.text }}
              <transition name="fade" mode="out-in">
                <div class="minute" v-if="i == 2" v-show="minuteState">
                  <template v-for="(item, i) in imgArr">
                    <a :key="i">
                      <img :src="item.imgSrc" alt="" :data-value="i" @click="to(i)" @mouseover="mouseoverFn" @mouseout="mouseoutFn" />
                    </a>
                  </template>
                </div>
              </transition>
            </a>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import store from "@/store";
export default {
  components: {
    Menu: () => import("./component/Menu.vue"),
  },
  data() {
    return {
      show: true,
      time: null,
      minuteState: false,
      current: store.state.num, //切换标识
      color: store.state.color,
      title: [
        { id: 1, text: "首页" },
        { id: 2, text: "资讯动态" },
        { id: 3, text: "产品中心" },
        { id: 4, text: "项目案例" },
        { id: 5, text: "关于我们" },
      ],
      imgArr: [
        {
          imgSrc: require("@/assets/img/imgSrc1.png"),
          imgSrc1: require("@/assets/img/imgSrc111.png"),
        },
        {
          imgSrc: require("@/assets/img/imgSrc2.png"),
          imgSrc1: require("@/assets/img/imgSrc222.png"),
        },
        {
          imgSrc: require("@/assets/img/imgSrc3.png"),
          imgSrc1: require("@/assets/img/imgSrc333.png"),
        },
        {
          imgSrc: require("@/assets/img/imgSrc4.png"),
          imgSrc1: require("@/assets/img/imgSrc444.png"),
        },
        {
          imgSrc: require("@/assets/img/imgSrc5.png"),
          imgSrc1: require("@/assets/img/imgSrc555.png"),
        },
      ],
    };
  },

  methods: {
    jumpFn() {
      sessionStorage.setItem("num", 0);
    },
    mouseoverLiFn(n) {
      if (n === 2) {
        this.minuteState = true;
      }
    },
    mouseoutLiFn(n) {
      if (n === 2) {
        this.minuteState = false;
      }
    },
    //背景颜色切换
    mouseoverBjFn() {
      this.$refs.bcColor.style.background = this.color;
      this.show = false;
    },
    mouseoutBjFn() {
      this.$refs.bcColor.style.background = "transparent";
      this.show = true;
    },
    numFn() {
      store.state.num = sessionStorage.getItem("num") || 0;
      this.current = store.state.num;
    },
    go(index) {
      // console.log(index);
      this.current = index; //激活样式
      sessionStorage.setItem("num", index);
      store.state.num = sessionStorage.getItem("num");
      // store.state.num = num;
      //跳转
      switch (index) {
        case 0:
          this.$router.push({ path: "/" });
          break;
        case 1:
          this.$router.push({ path: "/news" });
          break;
        // case 2:
        //   this.$router.push({ path: "/tea" });
        //   break;
        case 3:
          this.$router.push({ path: "/teaCase" });
          break;
        case 4:
          this.$router.push({ path: "/home" });
          break;
        default:
          break;
      }
    },
    to(value) {
      // console.log(value);
      this.current = 2; //激活样式
      sessionStorage.setItem("num", 2);
      let num = sessionStorage.getItem("num");
      store.state.num = num;
      switch (value) {
        case 0:
          this.$router.push({ path: "/tea" });
          break;
        case 1:
          this.$router.push({ path: "/health" });
          break;
        case 2:
          this.$router.push({ path: "/society" });
          break;
        case 3:
          this.$router.push({ path: "/industry" });
          break;
        case 4:
          this.$router.push({ path: "/hygiene" });
          break;

        default:
          break;
      }
    },
    mouseoverFn(e) {
      // console.log(111);
      let value = e.target.getAttribute("data-value");
      let temp = e.target;
      temp.src = this.imgArr[value].imgSrc1;
    },
    mouseoutFn(e) {
      // console.log(111);
      let value = e.target.getAttribute("data-value");
      let temp = e.target;
      temp.src = this.imgArr[value].imgSrc;
    },
  },
  created() {
    this.numFn();
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  /* 移动端的CSS样式代码 */
  .header {
    // height: 2.76rem;
    .navigator {
      display: none;
    }
    .web {
      display: block;
      height: 50px;
      width: 100%;
      z-index: 88;
      position: fixed;
      top: 0;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        width: 8rem;
        height: 1.248rem;
        z-index: 99;
      }
      .icon {
        position: absolute;
        top: -3px;
        left: 0px;
        width: 19.5rem;
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  // .box-enter-active {
  //   animation: backInDown 1s;
  //   transition: all 1s;
  // }
  // .box-leave-active {
  //   animation: backOutDown 1s;
  //   transition: all 0.5s 1s;
  // }

  .header {
    .web {
      display: none;
    }
    .navigator {
      width: 100%;
      height: 1.01rem;
      position: absolute;
      top: 0;
      background-color: transparent;
      opacity: 1;
      z-index: 88;
      transition: all 0.5s;
      img {
        width: 3rem;
        height: 0.458rem;
        float: left;
        margin-top: 0.25rem;
        margin-left: 1.2rem;
      }
      .title {
        float: right;
        // margin-top: 0.31rem;
        margin-right: 1.36rem;
        li {
          float: left;
          margin-left: 0.52rem;
          a.active {
            color: #dd4d1f;
            border-bottom: 0.06rem solid #093c7f;
          }
          a {
            display: block;
            height: 0.95rem;
            color: #fff;
            font-size: 0.24rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 1rem;
            cursor: pointer;
          }
        }
      }
    }
    .navigator:hover li > a {
      color: #606060;
    }
    .minute {
      // display: block;
      width: 100%;
      height: 1.8rem;
      background-color: rgba(255, 255, 255, 0.8);
      border-top: 0.01rem solid #fff;
      padding-left: 3rem;
      position: absolute;
      // top: -2rem;
      top: 1rem;
      left: 0;
      // transition: all 1s 1s;
      a {
        display: block;
        float: left;
        img {
          height: 1.7rem;
          width: 1.28rem;
        }
      }
    }
  }
}
</style>
